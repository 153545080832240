<template>
  <v-pagination
    v-model="pagination.page"
    :length="pagination.length"
    :total-visible="pagination.totalVisible"
    prev-icon="keyboard_arrow_left"
    next-icon="keyboard_arrow_right"
    @input="onChangePage"
  />
</template>

<script>
import _ from 'lodash'

export default {
  props: ['value'],
  computed: {
    pagination: {
      get() {
        return this.value
      }
    }
  },
  methods: {
    onChangePage(page) {
      window.scrollTo(0, 0)
      this.$router.push({
        query: _.merge(_.cloneDeep(this.$route.query), { page })
      }, () => {})
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
